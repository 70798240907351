<template>
  <div class="agr">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <div class="my-title">
        <h2>自审制度</h2>
      </div>
      <div class="my-con">
        <span>
          为加强网络文化内容建设与管理，规范网络文化经营单位产品及服务内容自审工作，增强本公司自主管理能力和自律责任，确保网络文化健康，快速发展，根据《互联网文化管理暂行规定》，结合本单位网展需要，本制度络文化建设与管理的实际情况而发。
          <br>
          一、自审人员配备
          <br>

          依照《网络文化经营单位内容自审管理办法》的有关要求制定本制度；设立专门岗位及专人承担产品内容自审工作，且承担该工作的工作人员须取得自审人员岗位资格。
          <br>
          初审：对动漫产品行业有一定了解，对于即将上线产品进行全面的审阅，主要负责从专业角度对产品的社会价值和文学价值进行审查，把好政治观、知识观、文字关以及视觉效果关。
          <br>
          写出初审报告，并对产品提出取舍意见和修改建议。
          <br>
          复审：审看全部动漫内容，做出整体评价，针对初审过程中提出的问题进行解决。对于产品内容文字或者画面不符合要求的产品安排动漫事业部成员进行修改；对于主题、意识导向不符和要求的产品给予不予上线发布的通知。对整改后产品有针对性的复审，并对审核通过的产品签字确认。
          <br>
          终审：综合初审、复审意见和建议，对通过复审的产品内容，包括思想政治导向、学术质量、社会效果、是否符合国家的政策和文化部门的制度等做宏观把控，并在初审、复审无异议情况下对产品是否上线发布作出决定。
          <br>
          二、审查职责
          <br>
          所有审核人员应掌握内容审核的政策法规和相关知识，能独立表达审核意见；以最终保证本企业的产品和服务的合法性和合规性为目的，对在自身工作中发现的违法违规的产品及服务内容进行记录并提请终止，作出提交修改，督促修改，并且保管审查记录。
          <br>
          三、审核标准
          <br>
          1.内容审查：
          <br>
          ①违反宪法确定的基本原则的；
          <br>
          ②危害国家统一、主权和领土完整的；
          <br>
          ③泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
          <br>
          ④煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
          <br>
          ⑤宣扬邪教、迷信的；
          <br> ⑥散布谣言，扰乱社会秩序，破坏社会稳定的；
          <br> ⑦宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；
          <br> ⑧侮辱、诽谤他人，侵害他人合法权益的；
          <br> ⑨违背社会公德的；
          <br> ⑩有法律、行政法规和国家规定禁止的其他内容的。
          <br> 2.场景审核：
          <br> 场景不得表现出恐怖、血腥、变态、色情等不利于身心健康的环境；不能有宣扬淫秽、赌博、暴力或者教唆犯罪的；危害社会公德或者民族优秀文化传统的；
          <br> 3.声音审核：
          <br> 不宜含有过度的惊悚恐怖等不利于身心健康的词语、画面、及音响效果。
          <br> 4.文字审核：
          <br>
          安装屏蔽词库，并保证经常更新。屏蔽词库过滤后，自审人员人工再次审核，保证不出现污秽、敏感、辱骂、恐吓等此类词汇，坚决杜绝不健康词汇。不得包括宣扬武力、教唆犯罪、淫秽色情、宣扬迷信、赌博、***、宣扬邪教、血腥、宣扬反动等类型的字眼。
          <br> 四、审核流程
          <br> 1.对企业自主研发的产品，在研发阶段进行培训，对教材科目、背景、美术素材等进行初步的筛查；
          <br> 2.自审人员在产品公测之前，依据内容审查的相关规定，对产品及其服务内容（包括宣传推广和活动策划）进行审查，对违法违规的内容进行记录，并签发初审意见；
          <br> 3.对初审有问题的产品，退回研发企业或部门进行修改，并对修改后的内容进行复查；
          <br> 4.对内容的合法合规性不能准确判断的，应向省文化和旅游局申请行政指导；
          <br> 5.对复查仍有问题的，应按规定的内容重新进行审核；
          <br> 6.对审查完成的产品，自审人员提出同意进行公测的意见并签字确认，上交内容审查管理工作的主要负责人；
          <br> 7.内容审查管理工作的主要负责人签字确认后，根据规定要求，向文化行政管理部门提交产品备案；
          <br> 8.日常对产品和服务内容进行监督检查，包括产品版本更新后的内容、产品客户端、公司官网、产品官网、宣传推广和活动策划等各个方面和环节，发现问题提交检查意见，报本企业内容审查管理工作主要负责人；
          <br> 9.自审人员的所有审查意见应归档留存，保存时间不少于两年；
          <br> 除以上审核流程外，我公司特设监督检查小组，会不定期对已上线或未上线产品进行检查，一经发现问题，针对已上线产品，将立即下线，未上线产品将不予上线，相关人员也将给予相应的处理。
          <br> 五、有问题的文化产品处理方式
          <br> 对已经上线的文化产品内容进行自查。如发现产品载有违规内容的，将立刻下线并进行调整、修改，复审合格后，方可重新上线。日常对产品和服务内容进行监督检查，发现问题提交检查意见，报本企业内容审查管理工作主要负责人。
          <br>
          公司严格按照《互联网文化管理暂行规定》执行，如发现产品载有《互联网文化管理暂行规定》禁止内容的，未上线的将不予上线操作，进行调整修改；已上线产品，通知审核人员立刻下线并进行调整、修改，不下线或下线后不进行调整、修改又重新上线经营的，如果文化执法机构依照《互联网文化管理暂行规定》查处的话，公司将接受相关部门的处罚，并追究审核人和相关负责人的责任，严格处理。
          <br> 六、应急事件处理
          <br> 公司根据突发事件的类别制定不同应急处置措施。
          <br> （1）信息报送
          <br>
          发生影响或者可能影响平台稳定的突发事件后，领导工作小组应在一个小时内将事件情况、已采取的措施、联络人及联系方式等通过电话报给相关部门。不得迟报、谎报、瞒报和漏报，报告内容主要包括时间、时间性质、事件发展趋势和已经采取的措施等。应急处理过程中，要及时续报有关情况。
          <br> （2）先期处置
          <br> 发生突发时间后，平台要立即采取措施控制事态发展，根据职责和规定的权限启动本单位制订的相关应急预案，及时有效地进行先期处置，控制事态。
          <br> （3）应急处置
          <br> 领导工作小组确定突发风险事件后，应根据突发风险事件性质及事态严重程度，及时组织召开会议，针对不同突发风险事件，成立相应的处置工作小组。处置工作小组在领导工作小组的统一领导下，制定突发事件处置方案，及时开展处置工作。
          <br> 主要处置措施：
          <br> 1.联系有关负责人，将真实情况告知，并商议处理方案；
          <br> 2.立即对不实信息作出澄清或更正，尽量减少不良信息的影响；
          <br> 3.追查相关责任人，并要求其改正，情形严重者诉之法律处理；
          <br> 4.安抚用户，按照规定做好信息工作。
          <br> （4）后期处置
          <br> 突发事件结束后，应尽快消除突发风险事件的影响，并及时解除应急状态，恢复正常。同时总结经验，对突发事件的起因、性质、影响、责任、经验教训和恢复重建等问题进行调查评估，评估应急预案的实施效果，对本预案进行修订和完善。
          <br> （5）善后事宜
          <br> 由公司经营班子拟定关于善后事项的处理意见，包括遭受损失情况以及恢复经营的建议和意见，由公司董事会或股东会批准后执行。
          <br> 七、未成年人保护制度
          <br>
          为了贯彻文化主管部门关于经营性互联网文化单位建立未成年人管理制度的决定，切实加强和改进互联网文化经营单位监管，完善互联网文化单位未成年人管理制度，落实管理责任，坚决按照《中华人民共和国未成年人保护法》来执行，平台拟建立未成年人管理制度，从内部来着手加强互联网文化产品的监管，保证公司互联网文化产品后续合法、健康的经营。为了营造健康、文明、有序的网络环境，保障未成年人网络空间安全，保护未成年人合法网络权益，促进未成年人健康成长而制定的平台未成年人管理制度。
          <br> 1、加强平台信息内容建设
          <br> （1）内容过滤。对于未成年用户，使用内容过滤等策略限制内容展现和设置相应的社区权限；对平台网站的相关内容进行严格分类管理，过滤掉不适宜未成年用户观看的网站内容。
          <br> （2）内容发布。保护未成年人合法的网络活动，保障未成年人平等、充分、合理地使用网络，保护未成年人免受网络违法信息侵害，避免未成年人接触不适宜其接触的信息，保护未成年人网上个人信息，防范未成年人沉迷网络。
          <br>
          （3）内容推送。通过网络空间弘扬社会主义核心价值观、民族优秀文化和传统美德，倡导创作、提供和传播有利于未成年人健康成长的网络文化产品，建设未成年人精神家园。用正确的价值观指导算法，优化算法推荐，积极传播正能量；主动推送适合未成年用户观看的相关内容。
          <br> 2、定期对未成年用户进行网络信息安全教育
          <br>
          （1）平台内容管理者会大力开展对未成年人的网络道德教育和良好上网习惯的培养，通过多种形式使未成年人认识到网络的利弊，不良上网习惯对自己身心的危害性，引导未成年人正确地使用网络，把网络作为学习知识和提高素质的有效工具。
          <br> （2）平台会定期对未成年人上网进行指导和管理，充分发挥其在提高学习能力和良好学习习惯的阵地作用。
          <br> （3）平台会定期组织关于网络信息专题教育活动。通过活动培养广大未成年人健康、文明地使用互联网络的自觉性，不断提高辨别是非和拒绝不良信息侵害的能力。
          <br> 3、鼓励文化交流
          <br> 为引导未成年用户在互联网时代的健康成长，利用现代信息技术获取文化艺术的知识信息，消除互联网络的不良影响，倡导良好上网行为习惯，营造健康，文明平台文化氛围，制定该管理制度。
          <br> （1）平台鼓励未成年用户通过互联网络进行知识学习，了解信息，开阔眼界，沟通交流，丰富生活。平台积极为未成年用户进行网上学习与交流创造条件。
          <br> （2）未成年用户浏览平台时要自觉遵守国家制定的网络法律法规，严格遵守有关制度规章和纪律要求、健康、文明地使用互联网络。
          <br> 八、用户注册：
          <br>
          用户遵循自愿原则，注册本平台账号，在注册成为会员时应与本平台一起维护平台的名誉和利益，不得在论坛、播客等通讯工具上发布诽谤、诬陷等对本平台不利的信息和图片，用户有义务举报对本平台不利的相关言论和行为。如发现注册会员有该行为,本平台有权注销会员账户，并保留追究其法律责任的权利。若用户发现平台上有违法违规行为，可及时向全国文化市场举报，举报电话：12318
          <br> 九、举报受理：
          <br> 为维护我公司业务的良好市场形象，提高业务的可信度与知名度，延长业务的生命周期，增强更多移动用户的参与，本着“用完善的服务留住用户”的原则，结合目前用户反馈的情况，制定了严格详细的客服工作规范、流程。一个星期 7
          天，一天 24 小时为用户提供帮助服务，为用户解疑答难
          <br> 用户可通过网站留言、电话、邮寄信件等方式举报；
          <br> 电话方式，我们有专门的人员负责电话的接听和反馈，通常能够解决问题，将在15分钟之内提供解决方案；不能当时解决的问题，我们会留下对方的电话号码，保证在半个小时内给予第一答复；
          <br> 网站留言方式，我们有投诉部的网站管理员专门负责每日的邮件，保证在四个小时内给予第一次书面答复
          <br> 邮寄信件方式，我们由举报受理部门人员每人定时查收信件，记录举报人信息并将举报信息登记反馈并进行处理；
        </span>
        <br>


      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "agreement",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "潮悦传媒";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.agr {
  .container {
    text-align: center;
    padding-top: 30px;

    // 标题
    .my-title {
      font-size: 28px;
      font-weight: bold;
    }

    // 主容器
    .my-con {
      margin: 30px auto;
      border: 1px solid #ccc;
      font-size: 18px;
      text-align: left;
      line-height: 40px;
      padding: 20px;

      span {
        font-size: 14px;
        color: #888;
      }

      p {
        margin: 10px auto;
      }
    }
  }
}</style>
