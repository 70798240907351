<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>
    <div class="container">
      <h2>{{ this.title }}</h2>
      <p>
        <!-- <span>发布作者：{{ this.author }}</span> -->
        <span>发布时间：{{ this.time }}</span>
      </p>
      <div class="i-con">
        <img :src="this.img1" />
        <img :src="this.img2" />
        <img :src="this.img3" />
        <img :src="this.img4" />
        <img :src="this.img5" />
        <img :src="this.img6" />
        <img :src="this.img7" />
        <img :src="this.img8" />
        <img :src="this.img9" />
        <img :src="this.img10" />
        <img :src="this.img11" />
        <img :src="this.img12" />
        <img :src="this.img13" />
        <img :src="this.img14" />

        <img :src="this.img15" />
        <img :src="this.img16" />
        <img :src="this.img17" />
        <img :src="this.img18" />
        <img :src="this.img19" />
        <img :src="this.img20" />
        <img :src="this.img21" />
        <img :src="this.img22" />
        <img :src="this.img23" />
        <img :src="this.img24" />
        <img :src="this.img25" />
        <img :src="this.img26" />
        <img :src="this.img27" />
        <img :src="this.img28" />
        <img :src="this.img29" />
        <img :src="this.img30" />
        <img :src="this.img31" />
        <img :src="this.img32" />
        <img :src="this.img33" />
        <img :src="this.img34" />
        <img :src="this.img35" />
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  data() {
    return {
      href: "index.html",
      // 获取 id
      myId: this.$route.query.type,
      // 获取 标题
      title: "",
      // 简介
      concise: "",
      // 作者
      author: "",
      // 图片路径
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      img5: "",
      img6: "",
      img7: "",
      img8: "",
      img9: "",
      img10: "",
      img11: "",
      img12: "",
      img13: "",
      img14: "",

      img15: "",
      img16: "",
      img17: "",
      img18: "",
      img19: "",
      img20: "",
      img21: "",
      img22: "",
      img23: "",
      img24: "",
      img25: "",
      img26: "",
      img27: "",
      img28: "",
      img29: "",
      img30: "",
      img31: "",
      img32: "",
      img33: "",
      img34: "",
      img35: "",
      time: "",
      ac3: [],
      d1: [],
      d2: [],
      d3: [],
      sub: "",
    };
  },
  components: {
    NavHeader,
  },
  mounted() {
    this.init();

    document.title = "微趣";
  },
  methods: {
    init() {
      this.axios.get("/mock/sound.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        console.log(date);
        // 标题
        this.title = date.title;
        // 作者
        this.author = date.author;
        // 时间
        this.time = date.time;
        // 图片路径
        this.img1 = date.imgs1;
        this.img2 = date.imgs2;
        this.img3 = date.imgs3;
        this.img4 = date.imgs4;
        this.img5 = date.imgs5;
        this.img6 = date.imgs6;
        this.img7 = date.imgs7;
        this.img8 = date.imgs8;
        this.img9 = date.imgs9;
        this.img10 = date.imgs10;
        this.img11 = date.imgs11;
        this.img12 = date.imgs12;
        this.img13 = date.imgs13;
        this.img14 = date.imgs14;

        this.img15 = date.imgs15;
        this.img16 = date.imgs16;
        this.img17 = date.imgs17;
        this.img18 = date.imgs18;
        this.img19 = date.imgs19;
        this.img20 = date.imgs20;
        this.img21 = date.imgs21;
        this.img22 = date.imgs22;
        this.img23 = date.img23;
        this.img24 = date.imgs24;
        this.img25 = date.imgs25;
        this.img26 = date.imgs26;
        this.img27 = date.imgs27;
        this.img28 = date.imgs28;
        this.img29 = date.imgs29;

        this.img30 = date.imgs30;
        this.img31 = date.imgs31;
        this.img32 = date.imgs32;
        this.img33 = date.imgs33;
        this.img34 = date.imgs34;
        this.img35 = date.imgs35;

        this.ac3 = vl.slice(0, 7);
        this.sub = date.sub;
      });
    },

    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条3元，点击确定注册购买");
        this.$router.push({ path: "/register", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #eee;
  // padding: 20px 0;
  box-sizing: border-box;
  .container {
    text-align: center;
    margin-top: 60px;
    p {
      width: 100%;
      text-align: center;
      margin: 30px 0;
      span {
        font-size: 14px;
        margin: 0 15px;
      }
    }
    // 主容器1
    .i-con {
      width: 100%;
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}
</style>
