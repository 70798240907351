<template>
  <div class="agr">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="container">
      <div class="my-title">
        <h2>纠纷处理</h2>
      </div>
      <div class="my-con">


        <p style="white-space: normal">
          深圳市潮悦网络传媒有限公司（下称“深圳市潮悦网络传媒有限公司”）经营深圳市潮悦网络传媒有限公司网站及其相关软件、客户端。前述平台中包含众多用户上传的信息、第三方发布的信息、频道管理者发布的信息、以及其他来自互联网的信息。深圳市潮悦网络传媒有限公司为保护著作权人的合法权利，根据我国相关法律法规的规定，将通过以下途径保护深圳市潮悦网络传媒有限公司经营的网站内作品的著作权：
        </p>

        <p style="white-space: normal">“作品”是指受《中华人民共和国著作权法》保护的文学、艺术和科学作品，包括但不限于文字作品、美术作品、表演、录音录像制品等。</p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          “权利人”是指作品的著作权人或者经著作权人合法授权而享有作品的信息网络传播权的权利享有人。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          通知程序
        </p>

        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          权利人认为深圳市潮悦网络传媒有限公司经营的网站中相关信息、搜索或链接的内容所涉及的作品侵犯了其信息网络传播权或者被删除、改变了自己的权利管理电子信息的，可向深圳市潮悦网络传媒有限公司提交书面通知，要求删除该作品，或者断开该作品的链接。通知书需权利人亲笔签名，若为单位则需加盖单位公章。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          通知书应当包含下列内容：
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （一） 权利人的姓名（名称）、联系方式、地址、身份证复印件（自然人）、单位登记证明复印件（单位）；
        </p>


        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （二） 要求删除或者断开链接的侵权作品的准确名称和网络地址；
        </p>

        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          （三） 认为构成侵权的初步证明材料，包括但不限于对作品享有著作权或依法享有信息网络传播权的权属证明、创作手稿、经权威机构签发的作品创作时间戳、作品备案证书、列举证据证明侵权事实。
        </p>

        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          权利人应对通知书的真实性负责。若通知书的内容不真实，权利人将承担由此造成的全部法律责任。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          深圳市潮悦网络传媒有限公司收到权利人的通知及完整的证明材料后，将立即删除涉嫌侵权的作品，或者断开涉嫌侵权作品的链接。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          反通知程序
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          深圳市潮悦网络传媒有限公司将投诉通知到作品提供者，若内容提供者认为作品不侵权的可提供相关证据反通知到深圳市潮悦网络传媒有限公司。反通知书需作品提供者亲笔签名，若为单位则需加盖单位公章。
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          邮寄地址
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          公司名称：深圳市潮悦网络传媒有限公司
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          联系地址：深圳市龙岗区龙城街道爱联社区新丰路6号台中工业区A4栋商洲创意园221
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          联系电话：13169908614
        </p>
        <p style="white-space: normal"><br /></p>
        <p style="white-space: normal">
          电子邮箱：154067771@qq.com
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "./../components/NavHeader";
export default {
  name: "agreement",
  components: {
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "潮悦传媒 ";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.agr {
  .container {
    text-align: center;
    padding-top: 30px;

    // 标题
    .my-title {
      font-size: 28px;
      font-weight: bold;
    }

    // 主容器
    .my-con {
      margin: 30px auto;
      border: 1px solid #ccc;
      font-size: 18px;
      text-align: left;
      line-height: 60px;
      padding: 20px;

      h4 {
        margin: 40px 0;
        font-size: 16px;
        color: #666;
      }

      p {
        margin: 10px auto;
      }
    }
  }
}</style>
