<template>
  <div class="nav-footer">
    <div class="w">
      <!-- 导航列表 -->
      <div class="f-list">
        <div class="w">
          <a href="/">网站首页</a> <span>-</span> <a href="/#/info">自审制度</a>
          <span>-</span> <a href="/#/xy3">纠纷处理</a>
          <span>-</span>
          <a href="/#/xy">用户协议</a>
        </div>
      </div>
      <!-- <div class="f-list">
        <div class="w">
          <a href="https://www.shdf.gov.cn/shdf/channels/740.html">扫黄打非入口</a>
          <span>-</span>
          <a href="https://www.12377.cn/">举报专区</a>

          <span>-</span>
          <a href="http://cyberpolice.mps.gov.cn/wfjb/">网络110报警服务</a> <span>-</span>
          <a href="https://www.12377.cn/">中国互联网举报中心</a>
        </div>
      </div> -->
      <!-- <div class="f-list">
        <div class="w">
          <a href="https://www.12377.cn/">网络有害信息举报</a>
          <span>-</span>
          <a href="https://www.12377.cn/node_548446.htm">网络举报APP下载</a>
          <span>-</span>
          <a href="https://jbts.mct.gov.cn/">全国文化和旅游市场网上举报投诉</a>

          <span>-</span>
          <a href="https://www.beian.gov.cn/portal/index.do">经营性网站备案信息</a>
        </div>
      </div> -->

      <!-- 网站信息 -->
      <div class="f-info">
        <!-- 公司名称 -->
        <p>
          深圳市潮悦网络传媒有限公司 -- 联系电话：13169908614 -- 联系邮箱：154067771@qq.com
        </p>
        <p>注册地址：深圳市龙岗区龙城街道爱联社区新丰路6号台中工业区A4栋商洲创意园221</p>

        <!-- <p>
          互联网举报中心 电话：0951-6668020 --
          违规不良信息举报：电话0951-7687668、邮箱15037333097@163.com
        </p> -->

        <p>
          © 2022-2023 www.szydtx.com 版权保护声明 --
          <a href="https://beian.miit.gov.cn/#/Integrated/index">网站备案： 粤ICP备2022065434号</a>

        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {},
  data() {
    return {
      // 网页的基础数据配置
      dataSourc: {},
    };
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-footer {
  width: 100%;
  background-color: #ececec;
  // padding-bottom: 35px;
  box-sizing: border-box;

  .w {
    text-align: center;
    box-sizing: border-box;
    padding: 20px;

    // 上层
    .f-list {
      width: 100%;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;

      a {
        color: #888;
      }

      span {
        margin: 0 10px;
        color: #333;
      }
    }

    // 信息层
    .f-info {
      p {
        color: #888;
        margin: 15px 0;

        a {
          color: #888;
        }
      }
    }
  }
}
</style>
