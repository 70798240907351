<template>
  <div class="index">
    <!-- 导航栏 -->
    <nav-header :href="this.href"></nav-header>

    <div class="banner">
      <img src="/imgs/i-banner.jpg" alt="" />
    </div>

    <!-- 定宽布局 -->
    <div class="w">
      <!-- 表情包展示 st -->
      <div class="cm-con">
        <!-- 标题 -->
        <div class="con-title">
          <p>Popular animation</p>
          <h2>热门动漫</h2>
        </div>

        <!-- 循环容器 -->
        <div class="cm-list">
          <!-- 循环项 *1 -->
          <a href="javascript:;" class="cm-item" v-for="(item, index) in this.dataList" :key="index"
            @click="goDays(item.methods, item.id)">
            <!-- 图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
            </div>

            <!-- 名称 -->
            <div class="item-name">
              <p>[热门动漫] {{ item.title }} {{ item.pay }}</p>
            </div>
          </a>
        </div>
      </div>
      <!-- 表情包展示 end -->

      <!-- swiper 轮播 -->
      <div class="swiper">
        <!-- <img src="/imgs/banner.jpg" alt="" /> -->
        <div class="swiper-box">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
              <a href="javascript:;"><img :src="item.img" /></a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>

      <!-- 关于我们 st -->
      <div class="us-con">
        <!-- 标题 -->
        <div class="con-title">
          <p>About us</p>
          <h2>联系我们</h2>
        </div>
        <!-- 容器 -->
        <div class="con-info">
          <img src="/imgs/us.jpg" alt="" />
          <p>
            <!-- 深圳市潮悦网络传媒有限公司成立于2020年04月03日，经营范围包括一般经营项目是：应用软件技术开发；电子产品、数码产品的设计及销售；电脑图文设计；数码产品、文化用品、通讯器材、计算机软硬件的销售、影视动画设计；平面设计；动漫产品技术开发；经营电子商务；网络营销策划；影视项目策划等。。。 -->
          </p>
          <img class="sc" src="/imgs/f.png" alt="">
        </div>
      </div>
      <!-- 关于我们 end -->

      <!-- banner 插图 -->
      <div class="banner">
        <img src="/imgs/acbanner.jpg" alt="" />
      </div>

      <!-- 分页器 -->
      <navigation :pages="pages" :current.sync="pageNo" @navpage="msgListView" style="text-align: center; display: none">
      </navigation>
      <!-- modal 弹框 -->
      <modal title="图片" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false"
        @cancel="showModal = false">
        <template v-slot:body>
          <img :src="imgUrl" />
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import NavHeader from "./../components/NavHeader";
import navigation from "./../components/navigation";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "index",
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      nv_user: "",
      nv_user2: JSON.parse(window.sessionStorage.getItem("user")),
      href: "/",
      // 根数据
      dataList: [],
      dataList2: [],
      dataList3: [],
      // 总页数
      pages: 7,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      my_data2: [],
      my_data3: [],
      // 是否显示弹框
      showModal: false,

      imgUrl: "",
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/s1.jpg",
        },
        {
          id: "2",
          img: "/imgs/s2.jpg",
        },
      ],
    };
  },
  components: {
    NavHeader,
    navigation,
    Modal,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    var user = window.sessionStorage.getItem('user');
    if (user) {
      this.nv_user = JSON.parse(user);
      if (this.nv_user.Username == "mxw") this.init2();
      if (this.nv_user.Username != "mxw") this.init();
    } else {
      this.init();
    }



    // alert(this.nv_user.uname)
    // if (this.nv_user.Username == "mxw") {
    //   this.init2();
    // } else {
    //   this.init();
    // }

    document.title = "潮悦传媒";
  },
  methods: {
    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      console.log(this.curPage);
      this.init();
    },

    init() {
      // 渲染数据
      this.axios.get("/mock/a1.json").then((res) => {
        console.log(res);
        var data = res.data;
        // 根数据
        this.dataList = data;
      });
    },

    init2() {
      // 渲染数据
      this.axios.get("/mock/a2.json").then((res) => {
        console.log(res);
        var data = res.data;
        // 根数据
        this.dataList = data;
      });
    },
    // 跳转 逻辑 封装
    goDays(methodsWords, type) {
      this[methodsWords](type);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + 1,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay(id) {
      // if (this.state == 0) {
      //   this.$router.push("/pay");
      //   return;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("点击登录去购买~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }

      this.$router.push({
        path: "/sound2/:" + 1,
        query: { type: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
@import url("http://www.jq22.com/jquery/bootstrap-3.3.4.css");

.index {
  .banner {
    width: 100%;
    height: 400px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .w {
    margin: 30px auto;

    // swiper 轮播 区块
    .swiper {
      width: 100%;
      height: 500px;
      margin: 30px 0;
    }

    // banner
    .banner {
      width: 100%;
      height: 300px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    // 表情包展示区块
    .cm-con {
      width: 100%;
      margin: 30px 0;

      // 标题
      .con-title {
        width: 100%;
        text-align: center;

        p {
          color: #999;
          font-size: 18px;
          letter-spacing: 10px;
          margin: 10px 0;
        }

        h2 {
          font-size: 26px;
          color: #333;
          font-weight: bold;
        }
      }

      // 循环容器
      .cm-list {
        background-color: #e9e9e9;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;

        // 循环项
        .cm-item {
          width: 332px;
          // height: 350px;
          margin: 10px;
          text-decoration: none;

          // 图片
          .item-img {
            width: 100%;
            height: 430px;
            border: 2px solid #e9e9e9;
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
              border: 2px solid $colorZ;
            }

            img {
              width: 100%;
              height: 100%;
              // background-color: pink;
            }
          }

          // 名称
          .item-name {
            width: 100%;
            height: 90px;
            cursor: pointer;
            background: $colorZ;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              width: 80%;
              margin: 4px auto;
              font-size: 16px;
              height: 70px;
              text-align: center;
              line-height: 70px;
              box-shadow: 0px 0px 10px #fff;
              color: #fff;
              transition: all 0.3s;

              &:hover {
                box-shadow: 2px 2px 5px #fefefe;
              }
            }
          }
        }
      }
    }

    // 关于我们
    .us-con {
      width: 100%;
      margin: 30px 0;

      // 标题
      .con-title {
        width: 100%;
        text-align: center;
        margin: 80px 0 20px;

        p {
          color: #999;
          font-size: 18px;
          letter-spacing: 10px;
          margin: 10px 0;
        }

        h2 {
          font-size: 26px;
          color: #333;
          font-weight: bold;
        }
      }

      // 容器
      .con-info {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #e9e9e9;

        .sc {
          width: 62%;
          height: 59%;
          margin-left: -7%;
        }

        img {
          width: 40%;
          height: 100%;
        }

        p {
          width: 50%;
          font-size: 16px;
          line-height: 60px;
          padding: 15px;
          box-sizing: border-box;
          text-indent: 30px;
        }
      }
    }

    // banner 插图
    .banner {
      width: 100%;
      height: 400px;
      background: pink;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
