<template>
  <div class="nav-header">
    <!-- 上层 st -->
    <div class="n-top">
      <div class="container">
        <!-- logo -->
        <div class="n-logo">
          <!-- <img src="/imgs/logo.png" alt="" /> -->
          <h4>潮悦传媒</h4>
        </div>
        <!-- 介绍 -->
        <div class="n-jj">
          <h2>漫画连接世界,创意赢得未来</h2>
          <p>在文化和媒体的世界里，创造力改变生活。</p>
        </div>

        <!-- 联系方式 -->
        <div class="n-phone">
          <i class="iconfont icon-dianhua_o"></i>
          <div class="phone-r">
            <p>联系方式：</p>
            <h2>13169908614</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- 上层 end -->
    
    <!-- 下层导航 st -->
    <div class="n-list">
      <div class="container">
        <a href="/" class="active">网站首页</a>
        <!-- <a href="/#/ac1">动漫产品</a> -->
        <a href="/#/info">自审制度</a>
        <a href="/#/xy3">纠纷处理</a>
        <a href="/#/xy">用户协议</a>
        <a href="javascript:;" @click="goLogin()" v-show="this.LoggedShow">登录 / 注册</a>
        <!-- <a href="javascript:;" @click="goRegister()" v-show="this.LoggedShow">注册</a> -->
        <a
          href="javascript:;"
          v-show="!this.LoggedShow"
          v-text="this.uname"
        ></a>
        <a href="javascript:;" @click="tolg()" v-show="!this.LoggedShow">退出登录</a>
      </div>
    </div>
    <!-- 下层导航 end -->
  </div>
</template>

<script>
export default {
  components: {},
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      uname:'',
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user:"",
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    var user=window.sessionStorage.getItem('user');
    if(user){
      this.nv_user=JSON.parse(user);
      this.uname=this.nv_user.Username;
    }

    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.nv_user) {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.sessionStorage.removeItem('user')
      window.localStorage.setItem("state", "10");
      this.$router.push("/index");
      this.LoggedShow = true;
      // localStorage.clear();
      location.reload();

      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.nav-header {
  width: 100%;
  // 上层
  .n-top {
    width: 100%;
    height: 100px;
    background-color: #fff;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      // logo
      .n-logo {
        width: 200px;
        height: 80px;
        display: flex;
        align-items: center;
        h4 {
          font-size: 21px;
          font-weight: bold;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      // 介绍
      .n-jj {
        width: 60%;
        margin-left: 20px;
        border-left: 1px solid #ccc;
        padding-left: 20px;
        box-sizing: border-box;

        h2 {
          font-size: 20px;
          color: #333;
          font-weight: bold;
        }
        p {
          font-size: 12px;
          color: #999;
          margin-top: 10px;
        }
      }
      // 联系方式
      .n-phone {
        display: flex;
        flex: 1;
        i {
          font-size: 48px;
          font-weight: bold;
          color: $colorZ;
          margin-right: 3px;
        }
        .phone-r {
          p {
            font-size: 14px;
            color: #333;
          }
          h2 {
            color: $colorZ;
            font-size: 28px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  // 下层导航
  .n-list {
    width: 100%;
    background: $colorZ;
    height: 70px;
    .container {
      height: 100%;
      display: flex;
      align-items: center;
      a {
        flex: 1;
        height: 70px;
        color: #fff;
        text-align: center;
        line-height: 70px;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          background-color: #4184f3;
        }
      }
    }
  }
}
</style>
